.nav {
    background: #0e0e33;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
}

.nav-container {
    display: flex;
    justify-content: space-evenly;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;
}

.nav-logo {
    width: 80%;
    padding: 0 6px;
    display: flex;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
}

.logo-text {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.mobile-icon {
    display: none;

}

.nav-items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    list-style: none;
}

.nav-item {
    color: #F2F3F4;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.nav-link {
    color: #FFFFFF;
    text-decoration: none;
}

.nav-link:hover {
    color: #1894ec;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 80%;
    height: 100%;
    padding: 0 6px;
}

@media screen and (max-width: 760px) {
    .nav-items {
        display: none;
    }

    .mobile-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #F2F3F4;
}
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    padding: 12px 40px 24px 40px;
    background: #191924;
    transition: all 0.6s ease-in-out;
}


.mobile-menu.open {
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
}

.mobile-link {
    color: #F2F3F4;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.mobile-link:hover {
    color: #854CE6;
}
