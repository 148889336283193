.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    background: #0e0e33;

}

.wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    gap: 12px;
}


.skills-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 30px;
    justify-content: center;
}

.skill {
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, rgb(7, 7, 33) 0%, rgb(25, 25, 65) 100%);
    border-radius: 8px;
    padding: 18px 36px;
}

.skill-title {
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.skill-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
}

.skill-item {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    width: 200px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgb(27, 27, 89);
    animation: slideInFromTop 2s ease-in-out forwards;
}

.skill-item:hover {
    background: #1894ec;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.skill-image {
    width: 24px;
    height: 24px;
}
