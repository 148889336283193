.home-container {
    background: #0e0e33;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -12vh;
    padding: 80px 30px;
    height: 100vh;


}

.home-flex {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-right: 30vw;
    flex-direction: column;
}

.home-bg {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 1360px;
    overflow: hidden;
    padding: 0 30px;
    transform: translateX(-50%) translateY(-50%);
}

.home-inner-container {
    position: relative;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: start;
    margin-bottom: -5vh;
    width: 100%;
    max-width: 1100px;
    animation: slideInFromTop 2s ease-in-out forwards;


}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.home-bottom {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 8px;
}

.title {
    font-weight: 700;
    font-size: 50px;
    color: #FFFFFF;
    line-height: 68px;
}

.subtitle {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 42px;
    color: #1894ec;
}


.animated-thread {
    position: absolute;
    width: 10000px;
    height: 2px;
    background-color: #1894ec;
    animation: wave 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
}

@keyframes waveAnimation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.github-button, .linkedin-button {
    border: none;
    height: 6vh;
}


.github-link, .linkedin-link {
    cursor: pointer;
    position: relative;
}


